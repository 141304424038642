@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

* {
  font-family: "Spoqa Han Sans Neo", "sans-serif";
}
body {
  background-color: #faf8fa;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-bg-fill-color: white;
  transition: background-color 5000s ease-in-out 0s;
  color: black;
}
